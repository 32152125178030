import React from "react";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import dimensions from "styles/dimensions";
import Layout from "components/Layout";
import SEO from "components/SEO";
import colors from "styles/colors";
import ScrollIndicator from "components/ScrollIndicator";
import Timeline from "components/Timeline";
import Reveal from "react-reveal/Reveal";
import { drawIn, fadeIn } from "styles/animations";
import useWindowDimensions from "../hooks/useWindowSize";

const HistoryContainer = styled.div`
  position: relative;
  background: ${colors.grey200};
  
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    
  }
`;

const HistoryHero = styled.div`
  padding: 8rem 3rem 14.4rem 8rem;
  
  h6 {
    display: block;
    position: relative;
    font-size: 2.4rem;
    line-height: 1.2;
    max-width: 102.4rem;
    animation: ${fadeIn} 1s ease-in;
    
    &::before {
      content: 'Our History';
      display: block;
      color: ${colors.red600};
      font-size: 1.4rem;
      font-weight: bold;
      margin-bottom: 0.8rem;
    }
    
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 3.4rem;
      right: calc(100% + 0.8rem);
      width: 100vw;
      height: 0.3rem;
      background-color: ${colors.red600};
      animation: ${drawIn} 0.5s cubic-bezier(0.22, 1, 0.36, 1) 0.1s forwards;
    }
  }
  
  @media (min-width: ${dimensions.tabletPortraitUp}px) {
    padding-left: 21vw;
    padding-right: 4rem;
  }
  
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    padding-left: 12vw;
    
    h6 {
      font-size: 4.8rem;
      
      &::after {
        top: 4.6rem;
        right: calc(100% + 3.2rem);
      }
    }
  }
`;

const History = ({ data }) => {
  const history = data.prismic.allHistorys.edges[0].node;
  const timeline = history.body;

  // width required to pass to the history component and change the y coordinates depending on mobile or desktop design
  const { width } = useWindowDimensions();

  if (!history) return null;
  return (
    <Layout>
      <SEO
        title={history.social_title || "Our History"}
        description={
          history.social_description ? history.social_description : undefined
        }
        image={history.social_image ? history.social_image.url : undefined}
      />

      <ScrollIndicator/>

      <HistoryContainer>
        {history.heading && (
          <Reveal effect="drawIn">
            <HistoryHero>
              <h6>{history.heading}</h6>
            </HistoryHero>
          </Reveal>
        )}

        <Timeline timeline={timeline} width={width} />
      </HistoryContainer>
    </Layout>
  );
};
export default History;

export const query = graphql`
  {
    prismic {
      allHistorys {
        edges {
          node {
            feature_image
            short_description
            heading
            body {
              ... on PRISMIC_HistoryBodyTimeline_section {
                type
                primary {
                  year
                }
                fields {
                  image
                  secondary_image
                  month
                  text
                  text_size
                }
              }
            }
          }
        }
      }
    }
  }
`;
