import React, { Component } from "react";
import styled from "@emotion/styled";
import colors from "styles/colors";
/*import dimensions from "styles/dimensions";*/
import { document } from "browser-monads";

const ScrollContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0.6rem;
  background-color: ${colors.grey200};
  z-index: 1000;
`;

const ScrollBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${colors.red600};
  transform-origin: center left;
  transition: transform 0.1s ease-out;
`;

export default class ScrollIndicator extends Component {
  state = {
    scrollPer: 0,
  };

  componentDidMount() {
    document.addEventListener("scroll", this.trackScrolling);
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.trackScrolling);
  }

  trackScrolling = () => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    const scrolled = (winScroll / height);

    this.setState({
      scrollPer: scrolled,
    });

    console.log(this.state.scrollPer);
  };

  render() {
    return (
      <ScrollContainer>
        <ScrollBar style={{transform: `scaleX(${this.state.scrollPer})`}}/>
      </ScrollContainer>
    );
  }
}
