import React, { Component } from "react";
import styled from "@emotion/styled";
import colors from "styles/colors";
import dimensions from "styles/dimensions";
import { Parallax } from "react-scroll-parallax";
import Content from "components/_ui/Content";
import marinoLogo from "images/icon_marino--black-background.png";

const TimelineSection = styled.div`
  position: relative;
  overflow: hidden; 
  
  & + * {
    margin-top: 8rem;
  }
`;

const TimelineInner = styled.div``;

const Text = styled.div`  
  position: relative;
  display: flex;
  width: 100%;
  z-index: 11;
  ${props => props.even ? `justify-content: flex-end;` : `justify-content: flex-start;`}

  p {
    padding: 2rem 3.2rem;
    background: #fff;
    font-size: 1.6rem;
    min-width: 30rem;
    max-width: 60rem;
    width: 50vw;
    ${props => props.even && `margin-left: 28vw;`}
    
    @media (min-width: ${dimensions.tabletLandscapeUp}px) {
      font-size: 2rem;
      padding: 2.8rem 2rem;
    }
  }

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    padding: ${props => (props.large ? "6.4rem 4rem" : "4rem")};
    font-size: ${props => (props.large ? "4rem" : "2rem")};
    line-height: ${props => (props.large ? "5rem" : "3rem")};
  }
`;

const Year = styled.div`
  position: absolute;
  top: 0;
  margin-left: 8rem;
  z-index: 11;
  
  h2 {
    color: ${colors.red600};
    font-size: 18vw;
    font-weight: bold;
    transition: 1s;
    letter-spacing: -0.04em;
  }
  
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    margin-left: 12vw;
    top: auto;
    bottom: 0;
   
    h2 {
      font-size: 30rem;
      letter-spacing: -2rem;
      font-weight: 600;
      transition: 0.4s;
    }
  }
`;

const Logo = styled.div`
  position: relative;
  transition: 1s;
  z-index: 10;
  width: 14.4rem;
  height: 14.4rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: block;
  }

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {    
    img {
      margin-left: 2rem;
    }
  }
  
  @media (min-width: ${dimensions.desktopUp}px) {
    width: 25.6rem;
    height: 25.6rem;
    transition: 1s;
  }
`;

const PrimaryImage = styled.div`
  position: relative;
  ${props => props.first && `margin-left:  3rem;`}
  ${props => !props.even && `margin-left: 28vw;`}
  transition: 1s;  

  img,
  .placeholder {
    width: 72vw;
    height: 50vw;
    object-fit: cover;
    display: block;
  }
  
  .placeholder {
    background-color: #999;
  }
  
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {

  }
`;

const OptionalImage = styled.div`
  display: inline-block;
  position: absolute;
  ${props => props.even ? 'margin-left: 60vw;' : 'margin-left: 4rem;'}

  img {
    height: 25vw;
    width: 36vw;
    object-fit: contain;
    display: block;
  }
  
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {

  }
`;

export default class Timeline extends Component {
  render() {
    const { timeline } = this.props;

    return (
      <>
        {timeline.length > 0 &&
          timeline.map(({primary, fields}, i) => {
            const evenYear = i % 2;
            const firstYear = i === 0;

            return fields.map((section, i) => {
              const evenSection = evenYear ? !(i % 2) : (i % 2);
              const firstSection = i === 0;

              return (
                <TimelineSection>
                  <TimelineInner key={i} even={evenSection}>
                    {(firstYear && firstSection) && (
                      <Logo>
                        <Parallax y={[360, -100]}>
                          <img src={marinoLogo} alt='Marino PR' />
                        </Parallax>
                      </Logo>
                    )}

                    {section.image ? (
                      <PrimaryImage even={evenSection} first={firstYear}>
                        <Parallax y={[0, 0]}>
                          <img src={section.image.url} alt={section.image.alt} />
                        </Parallax>
                       </PrimaryImage>
                    ): (
                      <PrimaryImage even={evenSection} first={firstYear}>
                        <Parallax y={[0, 0]}>
                          <div className='placeholder' />
                        </Parallax>
                      </PrimaryImage>
                    )}

                    {(primary.year && firstSection) && (
                      <Year>
                        <Parallax y={[0, -200]}>
                          <h2>{primary.year}</h2>
                        </Parallax>
                      </Year>
                    )}

                    {section.secondary_image && (
                      <OptionalImage even={evenSection}>
                        <Parallax y={[0, -400]}>
                          <img
                            src={section.secondary_image.url}
                            alt={section.secondary_image.alt}
                          />
                        </Parallax>
                      </OptionalImage>
                    )}

                    {section.text && (
                      <Text even={evenSection} large={section.text_size === "Large"}>
                        <Parallax y={[0, -100]}>
                          <Content content={section.text}/>
                        </Parallax>
                      </Text>
                    )}
                  </TimelineInner>
                </TimelineSection>
              )
            });
          })}
      </>
    );
  }
}
